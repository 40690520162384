<template>
    <b-form>
        <b-card no-body class="pl-2 pt-2 pb-2"> 
            <label class="font-weight-bold" style="font-size: 1.3rem"> Manage Roles </label>
        </b-card>   

        <b-row >
            <b-col cols="6" class="mb-1">
                <b-card class="h-100">
                    <b-card-body>
                        <b-row>
                            <b-col cols="8">
                                <b-form-group
                                    label="Sort"
                                    label-size="sm"
                                    label-align-sm="left"
                                    label-cols-sm="2"
                                    label-for="sortBySelect"
                                    class="mr-1 mb-md-0"
                                >
                                    <b-input-group
                                        size="sm"
                                    >
                                        <b-form-select
                                            id="sortBySelect"
                                            v-model="sortBy"
                                            :options="sortOptions"
                                        >
                                            <template #first>
                                                <option value="">
                                                    none
                                                </option>
                                            </template>
                                        </b-form-select>
                                        <b-form-select
                                            v-model="sortDesc"
                                            size="sm"
                                            :disabled="!sortBy"
                                        >
                                            <option :value="false">
                                                Asc
                                            </option>
                                            <option :value="true">
                                                Desc
                                            </option>
                                        </b-form-select>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>

                            <!-- <b-col cols="4">
                                <b-form-group
                                    label="Filter"
                                    label-cols-sm="2"
                                    label-align-sm="left"
                                    label-size="sm"
                                    label-for="filterInput"
                                    class="mb-0"
                                >
                                    <b-input-group size="sm">
                                        <b-form-input
                                            id="filterInput"
                                            v-model="filter"
                                            type="search"
                                            placeholder="Type to Search"
                                        />
                                        <b-input-group-append>
                                        <b-button
                                            :disabled="!filter"
                                            @click="filter = ''"
                                        >
                                            Clear
                                        </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                            </b-col> -->

                            <b-col cols="4" d-flex justify-content-between  flex-wrap>
                                <b-button 
                                    id="buttonNew" size="sm"
                                    variant="gradient-primary float-left"
                                    v-b-modal.modal-add>New Role </b-button>
                            </b-col>
                        </b-row>
                    </b-card-body>

                    <b-row>
                        <b-col cols='12'>
                            <b-table
                                striped
                                hover
                                responsive
                                selectable
                                select-mode="single"
                                class="position-relative"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :items="items"
                                :fields="fields"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :sort-direction="sortDirection"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                                @row-selected="onRowSelected"
                                >

                                <template #cell(action)="data">
                                    <!-- <b-button :id="data.role" variant="gradient-primary" size="sm" class="mr-1" @click="showPermissions(data.item.id, data.item.role)">
                                        Permissions
                                    </b-button> -->

                                    <b-button v-if="data.item.role !== 'superadmin'" :id="data.role" variant="gradient-primary" size="sm" @click="onActionDelete(data.item.id, data.item.role)">
                                        Delete
                                    </b-button>
                                </template> 
                            </b-table>
                        
                            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
                                <!-- page length -->
                                <b-form-group
                                    label="Per Page"
                                    label-cols="6"
                                    label-align="left"
                                    label-size="sm"
                                    label-for="sortBySelect"
                                    class="text-nowrap mb-md-0 mr-1"
                                >
                                    <b-form-select
                                    id="perPageSelect"
                                    v-model="perPage"
                                    size="sm"
                                    inline
                                    :options="pageOptions"
                                    />
                                </b-form-group>

                                <!-- pagination -->
                                <div>
                                    <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    first-number
                                    last-number
                                    prev-class="prev-item"
                                    next-class="next-item"
                                    class="mb-0"
                                    >
                                    <template #prev-text>
                                        <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                        />
                                    </template>
                                    <template #next-text>
                                        <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                        />
                                    </template>
                                    </b-pagination>
                                </div>
                            </b-card-body>

                        </b-col>
                    </b-row>
                </b-card>
            </b-col>

            <b-col cols="6" class="mb-1">
                <b-card class="h-100 " disabled>
                    <label> LIST PERMISSION {{subTitleListPermission}}</label>
                    
                    <b-row>
                        <b-col cols="6">
                            <b-card no-body>
                                <div class="ml-1 ">
                                    <label class="mt-1 mb-1" style="font-size: 1rem"> Station </label>
                                    <div class="ml-1">
                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.Station.Add']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        Add Station
                                        </b-form-checkbox>

                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.Station.Delete']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        Delete Station
                                        </b-form-checkbox>

                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.Station.Modify']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        Modify Station
                                        </b-form-checkbox>

                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.Station.View']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        View Station
                                        </b-form-checkbox>

                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.Station.HistoricalNotes.View']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        Station - Historical Notes
                                        </b-form-checkbox>
                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.Station.Geography.View']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        Station - Geography
                                        </b-form-checkbox>

                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.Station.Photos.View']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        Station - Photos
                                        </b-form-checkbox>

                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.Station.LocalMaps.View']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        Station - Local Maps
                                        </b-form-checkbox>

                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.Station.Element.View']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        Station - Elements
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </b-card>
                        </b-col>

                        <b-col cols="6">
                            <b-card no-body>
                                <div class="ml-1 ">
                                    <label class="mt-1 mb-1" style="font-size: 1rem"> Data Access</label>
                                    <div class="ml-1">
                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.Layanan.Export']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        Export
                                        </b-form-checkbox>

                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.Layanan.Report']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        Report
                                        </b-form-checkbox>

                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.Visualization.View']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        View Visualization
                                        </b-form-checkbox>

                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.Ingest.Access']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        Ingest
                                        </b-form-checkbox>

                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.Monitoring.MonitorAGM1a']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        Data Monitoring
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </b-card>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="6">
                            <b-card no-body>
                                <div class="ml-1 ">
                                    <label class="mt-1 mb-1" style="font-size: 1rem"> User Administration</label>
                                    <div class="ml-1">
                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.User.Manage']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        Manage Users
                                        </b-form-checkbox>

                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.Role.Manage']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        Manage Role Permissions
                                        </b-form-checkbox>

                                        <!-- <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.User.ViewLog']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        View User Logs
                                        </b-form-checkbox> -->

                                    </div>
                                </div>
                            </b-card>
                        </b-col>
                        <b-col cols="6">
                            <b-card no-body>
                                <div class="ml-1 ">
                                    <label class="mt-1 mb-1" style="font-size: 1rem"> Quality Control</label>
                                    <div class="ml-1">
                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.QC.Configuration']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        QC Configuration
                                        </b-form-checkbox>
                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.QC.Ruleset']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        QC Ruleset
                                        </b-form-checkbox>
                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.QC.Monitor']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        QC Monitor
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </b-card>
                        </b-col>
                        
                    </b-row>

                    <b-row>
                        <b-col cols="6">
                            <b-card no-body>
                                <div class="ml-1 ">
                                    <label class="mt-1 mb-1" style="font-size: 1rem"> Data Entry</label>
                                    <div class="ml-1">
                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.MegaEntry.Sinoptik.Add']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        Add Data Entry
                                        </b-form-checkbox>
                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.MegaEntry.Sinoptik.View']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        View Data Entry
                                        </b-form-checkbox>

                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.MegaEntry.Sinoptik.Modify']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        Edit Data Entry
                                        </b-form-checkbox>

                                    </div>
                                </div>
                            </b-card>
                        </b-col>

                        <b-col cols="6">
                            <b-card no-body>
                                <div class="ml-1 ">
                                    <label class="mt-1 mb-1" style="font-size: 1rem"> Web Portal</label>
                                    <div class="ml-1">
                                        <b-form-checkbox
                                            v-model="permissionMap['bmkgsatu.Setting.Balai.View']"
                                            value="1"
                                            :disabled="!isRoleSelected"
                                        >
                                        Setting Administration
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </b-card>
                        </b-col>
                    </b-row>
                
                </b-card>
            </b-col>
        </b-row>    

        <b-row >
            <b-col cols="12">
                <b-card no-body class="pl-2 pt-2 pb-2"> 
                    <b-row class="float-right"> 
                        <b-col>
                            <div class="float-right">
                                <b-button @click.prevent="onEditPermission" variant="primary" class="mr-2" size="sm">Save</b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <div>
            <!-- <template #code>
            {{ codeKitchenSink }}
            </template> -->
            <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>

            <!-- MODAL add user START -->
            <b-modal
                id="modal-add"
                ref="modal-add"
                title="Add Role"
                button-size="sm"
                size="md"
                centered
                no-close-on-backdrop
            >
                <b-row>
                    <b-col class="mb-1">
                        <label>Role Name</label>
                        <b-form-input
                            size="sm"
                            type="text"
                            v-model="newRole.bmkg_rolename"
                            @keydown.space.prevent
                        ></b-form-input>
                    </b-col>
                </b-row>
                <!-- <b-row>
                    <b-col class="mb-1">
                        <label>Role Description</label>
                        <b-form-input
                            size="sm"
                            type="text"
                            v-model="newRole.bmkg_roledesc"
                            @keydown.space.prevent
                        ></b-form-input>
                    </b-col>
                </b-row> -->

                <label>SELECT PERMISSIONS</label>
                <b-row>
                    <b-col class="mb-1">
                        <b-card>                            
                            <b-row>
                                <b-col cols="6">
                                    <b-card no-body>
                                        <div class="ml-1 ">
                                            <label class="mt-1 mb-1" style="font-size: 1rem"> Station </label>
                                            <div class="ml-1">
                                                <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.Station.Add']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                Add Station
                                                </b-form-checkbox>

                                                <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.Station.Delete']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                Delete Station
                                                </b-form-checkbox>

                                                <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.Station.Modify']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                Modify Station
                                                </b-form-checkbox>

                                                <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.Station.View']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                View Station
                                                </b-form-checkbox>

                                                <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.Station.HistoricalNotes.View']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                Station - Historical Notes
                                                </b-form-checkbox>
                                                <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.Station.Geography.View']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                Station - Geography
                                                </b-form-checkbox>

                                                <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.Station.Photos.View']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                Station - Photos
                                                </b-form-checkbox>

                                                <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.Station.LocalMaps.View']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                Station - Local Maps
                                                </b-form-checkbox>

                                                <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.Station.Element.View']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                Station - Elements
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                    </b-card>
                                </b-col>

                                <b-col cols="6">
                                    <b-card no-body>
                                        <div class="ml-1 ">
                                            <label class="mt-1 mb-1" style="font-size: 1rem"> Data Access</label>
                                            <div class="ml-1">
                                                <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.Layanan.Export']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                Export
                                                </b-form-checkbox>

                                                <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.Layanan.Report']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                Report
                                                </b-form-checkbox>

                                                <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.Visualization.View']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                View Visualization
                                                </b-form-checkbox>

                                                <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.Ingest.Access']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                Ingest
                                                </b-form-checkbox>

                                                <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.Monitoring.MonitorAGM1a']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                Data Monitoring
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                    </b-card>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="6">
                                    <b-card no-body>
                                        <div class="ml-1 ">
                                            <label class="mt-1 mb-1" style="font-size: 1rem"> User Administration</label>
                                            <div class="ml-1">
                                                <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.User.Manage']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                Manage Users
                                                </b-form-checkbox>

                                                <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.Role.Manage']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                Manage Roles
                                                </b-form-checkbox>

                                                <!-- <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.User.ViewLog']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                View User Logs
                                                </b-form-checkbox> -->

                                            </div>
                                        </div>
                                    </b-card>
                                </b-col>
                                <b-col cols="6">
                                    <b-card no-body>
                                        <div class="ml-1 ">
                                            <label class="mt-1 mb-1" style="font-size: 1rem"> Quality Control</label>
                                            <div class="ml-1">
                                                <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.QC.Configuration']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                QC Configuration
                                                </b-form-checkbox>
                                                <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.QC.Ruleset']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                QC Ruleset
                                                </b-form-checkbox>
                                                <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.QC.Monitor']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                QC Monitoring
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                    </b-card>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="6">
                                    <b-card no-body>
                                        <div class="ml-1 ">
                                            <label class="mt-1 mb-1" style="font-size: 1rem"> Data Entry</label>
                                            <div class="ml-1">
                                                <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.MegaEntry.Sinoptik.Add']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                Add Data Entry
                                                </b-form-checkbox>
                                                <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.MegaEntry.Sinoptik.View']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                View Data Entry
                                                </b-form-checkbox>

                                                <b-form-checkbox
                                                    v-model="newPermissionMap['bmkgsatu.MegaEntry.Sinoptik.Modify']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                Edit Data Entry
                                                </b-form-checkbox>

                                            </div>
                                        </div>
                                    </b-card>
                                </b-col>

                                <b-col cols="6">
                                    <b-card no-body>
                                        <div class="ml-1 ">
                                            <label class="mt-1 mb-1" style="font-size: 1rem"> Web Portal</label>
                                            <div class="ml-1">
                                                <b-form-checkbox
                                                    v-model="permissionMap['bmkgsatu.Setting.Balai.View']"
                                                    value="1"
                                                    class="custom-control-info"
                                                >
                                                Setting Administration
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                    </b-card>
                                </b-col>
                            </b-row>
                        
                        </b-card>
                    </b-col>
                </b-row>

                <template #modal-footer="{ cancel }">
                    <b-button size="sm" variant="gradient-danger" @click="cancel()">
                        Cancel
                    </b-button>
                    <b-button
                        size="sm"
                        variant="gradient-primary"
                        @click="
                            createNewRole();
                        "
                    >
                        Add
                    </b-button>
                </template>
            </b-modal>


            <!-- <b-modal
                id="modal-permission"
                ref="modal-permission"
                :title="titleListPermission"
                button-size="sm"
                size="sm"
                centered
                no-close-on-backdrop
                
                >
                <b-table
                    responsive="sm"
                    :items="permissionitems"
                    :fields="fieldPermission"
                    />
            </b-modal> -->
        </div>

    </b-form>
</template>

<script>
import Service from "@/api/rolepermissionservice";
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Helper from "@/api/helper";
import axios from "axios";
import {
    BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody,
    BCard, BRow, BCol, BOverlay, BModal, BFormCheckbox, BForm
} from 'bootstrap-vue'
// import { codeKitchenSink } from './code'

export default {
    components: {
        BCardCode,
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCardBody,
        BCard,
        BButton,
        BRow,
        BCol, 
        BOverlay,
        BModal,
        BFormCheckbox,
        BForm
    },
    data() {
        return {
            showLoading: false,
            perPage: 5,
            pageOptions: [5, 10, 15, 20],
            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: '',
            },
            fields: [ {key:'role', label: 'Role Name', sortable: true, tdClass: 'text-center', thClass: 'text-center',}, {key:'action', label: 'Actions', tdClass: 'text-center', thClass: 'text-center',}],
            items: [],
            newRole: {
                bmkg_rolename:'',
                perm_station_add:'',
                perm_station_delete:'',
                perm_station_view:'',
                perm_station_modify:'',
                perm_station_export:'',
                perm_station_report:'',
                perm_station_satelite:'',
                perm_station_visualization:'',
                perm_admin_users:'',
                perm_admin_roles:'',
                perm_admin_logs:'',
                perm_monitoring_data:'',
                perm_entry_view:'',
                perm_entry_edit:'',
                perm_entry_add:'',
            },
            fieldPermission: [{key:'permission', label: 'Permission', sortable: true}],
            permissionitems: [],
            allpermission: [],
            itemAllPermission: [],
            fieldAllPermission: [ {key:'selected', label: 'Selected', tdClass: 'text-center', thClass: 'text-center',}, {key:'permission', label: 'Permission'}],
            titleListPermission: 'Role: ',
            subTitleListPermission: '',
            isRoleSelected: false,
            roleSelected: '',
            rolePermExisting : '',
            permissionMap: {
                "bmkgsatu.Station.Add": "",
                "bmkgsatu.Station.Modify": "",
                "bmkgsatu.Station.Delete": "",
                "bmkgsatu.Station.View": "",
                "bmkgsatu.Station.HistoricalNotes.View": "",
                "bmkgsatu.Station.Geography.View": "",
                "bmkgsatu.Station.Photos.View": "",
                "bmkgsatu.Station.LocalMaps.View": "",
                "bmkgsatu.Station.Element.View": "",
                "bmkgsatu.Layanan.Export": "",
                "bmkgsatu.Layanan.Report": "",
                "bmkgsatu.Ingest.Access": "",
                "bmkgsatu.Visualization.View": "",
                "bmkgsatu.User.Manage": "",
                "bmkgsatu.Role.Manage": "",
                "bmkgsatu.Monitoring.MonitorAGM1a": "",
                "bmkgsatu.Monitoring.MonitorSinoptik": "",
                "bmkgsatu.MegaEntry.Sinoptik.Add": "",
                "bmkgsatu.MegaEntry.Sinoptik.Modify": "",
                "bmkgsatu.MegaEntry.Sinoptik.View": "",
                "bmkgsatu.Setting.Balai.View": "",
                "bmkgsatu.QC.Configuration": "",
                "bmkgsatu.QC.Monitor": "",
                "bmkgsatu.QC.Ruleset": ""
            },

            newPermissionMap: {
                "bmkgsatu.Station.Add": "",
                "bmkgsatu.Station.Modify": "",
                "bmkgsatu.Station.Delete": "",
                "bmkgsatu.Station.View": "",
                "bmkgsatu.Station.HistoricalNotes.View": "",
                "bmkgsatu.Station.Geography.View": "",
                "bmkgsatu.Station.Photos.View": "",
                "bmkgsatu.Station.LocalMaps.View": "",
                "bmkgsatu.Station.Element.View": "",
                "bmkgsatu.Layanan.Export": "",
                "bmkgsatu.Layanan.Report": "",
                "bmkgsatu.Ingest.Access": "",
                "bmkgsatu.Visualization.View": "",
                "bmkgsatu.User.Manage": "",
                "bmkgsatu.Role.Manage": "",                
                "bmkgsatu.Monitoring.MonitorAGM1a": "",
                "bmkgsatu.Monitoring.MonitorSinoptik": "",
                "bmkgsatu.MegaEntry.Sinoptik.Add": "",
                "bmkgsatu.MegaEntry.Sinoptik.Modify": "",
                "bmkgsatu.MegaEntry.Sinoptik.View": "",
                "bmkgsatu.Setting.Balai.View": "",
                "bmkgsatu.QC.Configuration": "",
                "bmkgsatu.QC.Monitor": "",
                "bmkgsatu.QC.Ruleset": ""
            }

        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => ({ text: f.label, value: f.key }))
        },
    },
    mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length
            console.log("user mounted");
		    this.getListAllRole();
            // if(this.allpermission && this.allpermission.length == 0) {
            //     this.getListAllPermission();
            // }
            
    },
    methods: {
        initNewMapPermission () {
            this.newPermissionMap = {
                "bmkgsatu.Station.Add": "",
                "bmkgsatu.Station.Modify": "",
                "bmkgsatu.Station.Delete": "",
                "bmkgsatu.Station.View": "",
                "bmkgsatu.Station.HistoricalNotes.View": "",
                "bmkgsatu.Station.Geography.View": "",
                "bmkgsatu.Station.Photos.View": "",
                "bmkgsatu.Station.LocalMaps.View": "",
                "bmkgsatu.Station.Element.View": "",
                "bmkgsatu.Layanan.Export": "",
                "bmkgsatu.Layanan.Report": "",
                "bmkgsatu.Ingest.Access": "",
                "bmkgsatu.Visualization.View": "",
                "bmkgsatu.User.Manage": "",
                "bmkgsatu.Role.Manage": "",
                "bmkgsatu.Monitoring.MonitorAGM1a": "",
                "bmkgsatu.Monitoring.MonitorSinoptik": "",
                "bmkgsatu.MegaEntry.Sinoptik.Add": "",
                "bmkgsatu.MegaEntry.Sinoptik.Modify": "",
                "bmkgsatu.MegaEntry.Sinoptik.View": "",
                "bmkgsatu.Setting.Balai.View": "",
                "bmkgsatu.QC.Configuration": "",
                "bmkgsatu.QC.Monitor": "",
                "bmkgsatu.QC.Ruleset": ""
            }
        },
        initMapPermission () {
            this.permissionMap = {
                "bmkgsatu.Station.Add": "",
                "bmkgsatu.Station.Modify": "",
                "bmkgsatu.Station.Delete": "",
                "bmkgsatu.Station.View": "",
                "bmkgsatu.Station.HistoricalNotes.View": "",
                "bmkgsatu.Station.Geography.View": "",
                "bmkgsatu.Station.Photos.View": "",
                "bmkgsatu.Station.LocalMaps.View": "",
                "bmkgsatu.Station.Element.View": "",
                "bmkgsatu.Layanan.Export": "",
                "bmkgsatu.Layanan.Report": "",
                "bmkgsatu.Ingest.Access": "",
                "bmkgsatu.Visualization.View": "",
                "bmkgsatu.User.Manage": "",
                "bmkgsatu.Role.Manage": "",
                "bmkgsatu.Monitoring.MonitorAGM1a": "",
                "bmkgsatu.Monitoring.MonitorSinoptik": "",
                "bmkgsatu.MegaEntry.Sinoptik.Add": "",
                "bmkgsatu.MegaEntry.Sinoptik.Modify": "",
                "bmkgsatu.MegaEntry.Sinoptik.View": "",
                "bmkgsatu.Setting.Balai.View": "",
                "bmkgsatu.QC.Configuration": "",
                "bmkgsatu.QC.Monitor": "",
                "bmkgsatu.QC.Ruleset": ""
            }
        },
        hideModal() {
			this.$refs['modal-add'].hide()
		},

        getPostDataUpdate() {

            let postdata  = []
            let tmpPermissionExisting = []

            for (var i = 0 ; i < this.rolePermExisting.length; i++) {
                let perm = this.rolePermExisting[i].split('.')
                if(perm[0] == "bmkgsatu") {
                    if (this.permissionMap.hasOwnProperty(this.rolePermExisting[i])) {

                        if(this.permissionMap[this.rolePermExisting[i]] == "") {
                            let data = {
                                "op": "remove",
                                "value": this.rolePermExisting[i]
                            }
                            postdata.push(data)
                        }
                        else {
                            tmpPermissionExisting.push(this.rolePermExisting[i])
                        }
                        
                    }
                }
            }

            for (const property in this.permissionMap) {
                if (this.permissionMap[property] == '1' && tmpPermissionExisting.indexOf(property) == -1) {
                    let data = {
                                "op": "append",
                                "value": property
                            }
                    postdata.push(data)
                }
            }

            return postdata
        },

        onEditPermission() {
            console.log('onEditPermission', this.roleSelected)

            let dataPermission = this.getPostDataUpdate()
            if (dataPermission.length < 1) {
                return 0
            }

            let post_data = {
                "@type": "BmkgRole",
                "bmkg_role_perm": {
                    "op": "multi",
                    "value":this.getPostDataUpdate()
                }
            }

            console.log('post_data', post_data)
            try {
				Service.updateRolePermission(this.roleSelected, post_data)
                    .then((response) => {
                        this.showLoading = false;

                        this.initMapPermission()
                        this.subTitleListPermission = ''
                        this.isRoleSelected = false
                        this.roleSelected = ''
                        let position = "top-center";
                        this.$toast(
                            {
                                component: ToastificationContent,
                                props: {
                                title: "Data telah disimpan",
                                icon: "BellIcon",
                                variant: "success",
                                },
                            },
                            { position }
                        );

                    })
                    .catch((error) => {
                        this.showLoading = false;
                        console.log("Eee : ", error.response);
                    });
			} catch (error) {
				console.log("error: ", error);
				this.showLoading = false;
			}
        },
        onRowSelected(items) {
            console.log('items', items)
            let nameRole = items[0].role
            this.roleSelected = 'bmkgsatu.'+nameRole
            this.isRoleSelected = true
            this.subTitleListPermission = ' ( '+ nameRole + ' )'

            this.showLoading = true;
			try {
				Service.getListPermissionByRole('bmkgsatu.'+nameRole)
                    .then((response) => {
                        this.showLoading = false;
                        // console.log("list-permission: ", response.data);

                        this.mappingPermissions(response.data)
                    })
                    .catch((error) => {
                        this.showLoading = false;
                        console.log("Eee : ", error.response);
                    });
			} catch (error) {
				console.log("error: ", error);
				this.showLoading = false;
			}
        },

        mappingPermissions(data) {
            let permissions = data.bmkg_role_perm
            let bmkgsatuPermission = []

            this.rolePermExisting = permissions

            for (var i = 0 ; i < permissions.length; i++) {
                let perm = permissions[i].split('.')
                if(perm[0] == "bmkgsatu") {
                    bmkgsatuPermission.push(permissions[i])
                }
            }
            // console.log('bmkgsatuPermission', bmkgsatuPermission)
            for (const property in this.permissionMap) {
                // console.log('property', property)
                let isExist = false
                for (var i = 0 ; i < bmkgsatuPermission.length; i++) {
                    if (property == bmkgsatuPermission[i]) {
                        this.permissionMap[property] = "1"
                        isExist = true
                        break
                    }
                }

                if (!isExist) {
                    this.permissionMap[property] = ""
                }
                
            }
        },

        showPermissions(url, rolename) {
            axios.get(url, Helper.getConfig())
                .then((response) => {
                    this.showLoading = false;
                    this.permissionitems = []
                    // console.log("list-permission: ", response.data);
                    if(response.data.bmkg_role_perm != null) {
                        for (var i = 0; i< response.data.bmkg_role_perm.length; i++) {
                            let item = {
                                    permission:response.data.bmkg_role_perm[i]
                                }
                            this.permissionitems.push(item)
                        }
                    }
                    
                    this.titleListPermission = "Role: "+rolename
                    this.$refs['modal-permission'].show()

                })
                .catch((error) => {
                    this.showLoading = false;
                    console.log("Eee : ", error.response.status);
                });
        },
        onActionDelete(id, name) {
            console.log('id: ', id)
            this.$swal({
				title: "Apakah Anda Yakin?",
				text: "Anda ingin menghapus role '" + name + "' ?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Delete",
				allowOutsideClick: false,
				allowEscapeKey: false,
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1",
				},
				buttonsStyling: false,
			}).then((result) => {
				if (result.value) {
					this.deleteRole(id, name);
				} else if (result.dismiss === "cancel") {
				return;
				}
			});
        },
        getListAllRole() {
            this.showLoading = true;
			try {
				Service.getListAllRole()
                    .then((response) => {
                        this.showLoading = false;
                        // console.log("list-roles: ", response.data.items);

                        for (var i = 0; i< response.data.length; i++) {
                            // let role_name = response.data[i]['@name'].split('.')
                            let role_name = response.data[i]['rolename'].split('.')
                            let item = {
                                    id:response.data[i].id,
                                    role:role_name[1],
                                    actions:''
                                }
                            this.items.push(item)
                        }
                        this.totalRows = this.items.length;
                    })
                    .catch((error) => {
                        this.showLoading = false;
                        console.log("Eee : ", error.response.status);
                    });
			} catch (error) {
				console.log("error: ", error);
				this.showLoading = false;
			}
        },
        showModalRole() {

        },

        getRolePermissions() {
            let roleperms = []
            for (const property in this.newPermissionMap) {
                if (this.newPermissionMap[property] == '1') {
                    let data = {
                                "op": "append",
                                "value": property
                            }
                    roleperms.push(data)
                }
            }
            return roleperms
        },

        createNewRole() {

            let roleperm = this.getRolePermissions()
            let post_data = ''

            if( roleperm.length < 1 ) {
                post_data = {
                    "@type":"BmkgRole",
                    flag_implement:3,
                    bmkg_rolename: 'bmkgsatu.'+this.newRole.bmkg_rolename
                };
            }
            else {
                post_data = {
                    "@type":"BmkgRole",
                    flag_implement:3,
                    bmkg_rolename: 'bmkgsatu.'+this.newRole.bmkg_rolename,
                    bmkg_role_perm: {
                        op: "multi",
                        value: roleperm   
                    }
                }
            }

            console.log('post_data', post_data)

            this.showLoading = true;
            try {
                Service.addNewRole(post_data)
                    .then((response) => {
                        this.showLoading = false;
                        // console.log("new-role: ", response.data);

                        let resData = response.data;
                        let newRole = {
                            id: response.data['@id'],
                            role: this.newRole.bmkg_rolename,//resData["@name"],
                            description:'',
                            actions:'',
                        };
                        this.items.push(newRole);
                        this.initNewMapPermission();
                        this.newRole.bmkg_rolename = ""
                        this.newRole.bmkg_roledesc = ""
                        let position = "top-center";
                        this.$toast(
                            {
                                component: ToastificationContent,
                                props: {
                                title: "Data telah tersimpan",
                                icon: "BellIcon",
                                variant: "success",
                                },
                            },
                            { position }
                        );
                        this.hideModal()
                    })
                    .catch((error) => {
                        this.showLoading = false;
                        // console.log("Eee : ", error.response.status);
                        if (error.response.status == 401) {
                        this.$swal({
                            title: "Unauthorized!",
                            text: "Sesi anda telah habis. Logout dan silahkan login kembali",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                        } else if (error.response.status == 409) {
                        this.$swal({
                            title: "Conflict!",
                            text: "Username telah ada.",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                        } else {
                        this.$swal({
                            title: "Something wrong!",
                            text: "" + error.response.status,
                            icon: "warning",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                        }
                    });
            } catch (error) {
                console.log("error: ", error);
                this.showLoading = false;
            }
        },

        deleteRole(id, name) {
			try {
				Service.deleteRole('bmkgsatu.'+name)
                    .then((response) => {
                        this.showLoading = false;
                        // console.log("delete Role: ", response.status);

                        this.items = this.items.filter((item) => item.id !== id);
                        this.totalRows = this.items.length;

                        let position = "top-center";
                        this.$toast(
                        {
                            component: ToastificationContent,
                            props: {
                            title: "Data telah dihapus",
                            icon: "BellIcon",
                            variant: "success",
                            },
                        },
                        { position }
                        );
                    })
                    .catch((error) => {
                        this.showLoading = false;
                        console.log("Eee : ", error.response.status);
                        if (error.response.status == 401) {
                        this.$swal({
                            title: "Unauthorized!",
                            text: "Sesi anda telah habis. Logout dan silahkan login kembali",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                        } else if (error.response.status == 404) {
                        this.$swal({
                            title: "Not Found!",
                            text: "Role ini tidak ada.",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                        } else {
                        this.$swal({
                            title: "Something wrong!",
                            text: "" + error.response.status,
                            icon: "warning",
                            customClass: {
                            confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                        }
                    });
			} catch (error) {
				console.log("error: ", error);
				this.showLoading = false;
			}
		},

        getListAllPermission() {
            Service.getListAllPermission()
                .then((response) => {
					this.showLoading = false;
					// console.log("all permission: ", response.data);
                    this.allpermission = response.data
                    this.updateItemPermissions(this.allpermission)
				})
				.catch((error) => {
                    console.log('err getListAllPermission', error)
                })
        },

        updateItemPermissions(items) {
            if(items) {
                for (var i = 0 ; i < items.length; i++) {
                    this.itemAllPermission.push({selected: false, permission: items[i]})
                }
            }
        },

        info(item, index, button) {
            this.infoModal.title = `Row index: ${index}`
            this.infoModal.content = JSON.stringify(item, null, 2)
            this.$root.$emit('bv::show::modal', this.infoModal.id, button)
        },
        resetInfoModal() {
            this.infoModal.title = ''
            this.infoModal.content = ''
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
    },
}
</script>

<style scoped>
.b-table-row-selected {
  background-color: #000;
  color: #fff;
}
</style>
